import { AccessControl } from "accesscontrol";

export enum AppRoles {
    ADMIN = "ADMIN",
    EDITOR = "EDITOR",
    DUMMY = "DUMMY"
}

export const ac = new AccessControl();
ac
    .grant(AppRoles.DUMMY)
    .read("sales")
    // editor
    .grant(AppRoles.EDITOR)
    .read(["sales"])
    .create("sales")
    .update("sales")
    .delete(["sales"])

    // admin
    .grant(AppRoles.ADMIN)
    .read(["persons", "sales"])
    .create(["persons", "sales"])
    .update(["persons", "sales"])
    .delete(["persons", "sales"]);
