import { useState } from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";

import { Create, Form, Input, Select, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICompany } from "interfaces";
import { axios } from "../../authProvider";

interface ClientData {
    id: number;
    name: string;
    age: number;
    notes: string;
}

export const salesTypes = [
    {
        label: "Ubrania",
        value: "ubrania",
    },
    {
        label: "Jedzenie",
        value: "jedzenie",
    },
    {
        label: "Chemia",
        value: "chemia",
    },
    {
        label: "Kosmetyki",
        value: "kosmetyki",
    },
    {
        label: "Ciepły posiłek",
        value: "ciepły posiłek",
    },
    {
        label: "Pościel",
        value: "pościel",
    },
    {
        label: "Świadczenie pieniężne",
        value: "świadczenie pieniężne",
    },
    {
        label: "Kąpiel",
        value: "kąpiel",
    }, {
        label: "Inne",
        value: "inne",
    },

]

export const SaleCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ICompany>();
    const [firstDisplay, setFirstDisplay] = useState(true);
    const [clients, setClients] = useState<ClientData[]>([]);
    const apiUrl = useApiUrl();

    const getClients = async (cardId: string) => {
        const data = await axios.get(apiUrl + "/persons/verify/" + cardId)
        data.data.forEach((client: any) => client.key = client.id);
        setClients(data.data);
    }


    const onCardIdInputChange = () => {
        const cardId = (document.querySelector("#cardId_field") as HTMLInputElement).value;
        if (cardId?.length === 5) {
            getClients(cardId);
        } else {
            setClients([]);
        }
    }

    if (firstDisplay) {
        setTimeout(() => onCardIdInputChange(), 200);
        setFirstDisplay(false);
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">

                <Form.Item
                    label="Numer karty"
                    name="cardId"
                    initialValue={localStorage.getItem("lastCheckedCardId")}
                    rules={[{required: true}]}
                    extra={
                        <span>{clients.length ? "Osoby przypisane do karty: " + clients.map(client => client.name).join(", ") : "Brak osób przypisanych do tej karty"}</span>}>
                    <Input maxLength={5} id={"cardId_field"} onChange={onCardIdInputChange}/>
                </Form.Item>


                <Form.Item
                    label="Typ"
                    name="type"
                    rules={[{required: true}]}>
                    <Select
                        allowClear
                        options={salesTypes}
                        placeholder="Typ"
                    />
                </Form.Item>

                <Form.Item
                    label="Donator"
                    name="donator"
                    style={{display: "none"}}
                    initialValue={localStorage.getItem("username")}>
                    <Input id={"donator"}/>
                </Form.Item>

                <Form.Item
                    label="Dodatkowe notatki"
                    name="notes">
                    <Input.TextArea/>
                </Form.Item>

            </Form>
        </Create>
    );
};
