import { IResourceComponentsProps } from "@pankod/refine-core";

import { Create, DatePicker, Form, Input, Typography, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IPerson } from "interfaces";
import dayjs from "dayjs";


export const PersonCreate: React.FC<IResourceComponentsProps> = () => {
    const {Title} = Typography;
    const {formProps, saveButtonProps} = useForm<IPerson>();
    console.log(formProps);
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">

                <Form.Item
                    label="Numer karty"
                    name="cardId"
                    rules={[{required: true}, {
                        validator: (field, value) => {
                            if (value.length === 0 || (value.length === 5)) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject("Numer karty powinien mieć 5 znaków")
                            }
                        }
                    }]}>
                    <Input  maxLength={5}/>
                </Form.Item>

                <Form.Item
                    label="Imię"
                    name="name"
                    rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nazwisko"
                    name="surname">
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="PESEL"
                    name="pesel"

                    rules={[{
                        validator: (field, value) => {
                            if (value && value.length === 9) {
                                const day = value.substr(4, 2);
                                let month = value.substr(2, 2);
                                let year = value.substr(0, 2);
                                if (month[0] === "2" || month[0] === "3") {
                                    month = parseInt(month, 10) - 20;
                                    year = "20" + year;
                                } else {
                                    year = "19" + year;
                                }
                                (formProps as any).form.setFieldsValue({
                                    birthdate: year + "-" + month + "-" + day
                                });
                            }
                            if (!value || value.length === 0 || (value.length === 9)) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject("PESEL powinien mieć 9 znaków")
                            }
                        }
                    }]}>
                    <Input maxLength={9}/>
                </Form.Item>


                <Form.Item
                    label="Numer innego dokumentu"
                    name="documentId">
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="Data urodzenia"
                    name="birthdate"
                    getValueProps={(value) => ({
                        value: value ? dayjs(value, "YYYY-MM-DD") : "",
                    })}
                    normalize={value => value.format("YYYY-MM-DD")}>

                    <DatePicker format={"YYYY-MM-DD"}/>
                </Form.Item>


                <Form.Item
                    label="Numer telefonu"
                    name="phone">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Dodatkowe notatki"
                    name="notes">
                    <Input.TextArea/>
                </Form.Item>

            </Form>
        </Create>
    );
};
