import { IResourceComponentsProps } from "@pankod/refine-core";

import { DatePicker, Edit, Form, Input, Select, Typography, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { ICompany } from "interfaces";
import dayjs from "dayjs";

let currentPerson: any;
export const PersonEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<ICompany>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">

                <Form.Item
                    label="Numer karty"
                    name="cardId"
                    rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Imię"
                    name="name"
                    rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nazwisko"
                    name="surname">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Numer dokumentu"
                    name="documentId">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="PESEL"
                    name="pesel"
                    rules={[{
                        validator: (field, value) => {
                            if (!value || value.length === 0 || (value.length === 9)) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject("PESEL powinien mieć 9 znaków")
                            }
                        }
                    }]}>
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="Data urodzenia"
                    name="birthdate"
                    getValueProps={(value) => ({
                        value: value ? dayjs(value, "YYYY-MM-DD") : "",
                    })}
                    normalize={value => value.format("YYYY-MM-DD")}>

                    <DatePicker format={"YYYY-MM-DD"}/>
                </Form.Item>

                <Form.Item
                    label="Numer telefonu"
                    name="phone">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Status karty"
                    name="cardStatus">
                    <Select
                        options={[
                            {
                                label: "AKTYWNA",
                                value: 0,
                            },
                            {
                                label: "NIEAKTYWNA",
                                value: 1,
                            },
                        ]}
                        placeholder="Status karty"
                    />
                </Form.Item>

                <Form.Item
                    label="Dodatkowe notatki"
                    name="notes">
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
